import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useValidationAccountData } from "../../../providers/ValidationAccountProvider";
import { onFiltersChanged } from "../../../reducers/validationAccounts/validationAccountReducer";
import ValidationAccountService from "../../../services/ValidationAccountService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import ListView from "../../ListView/ListView";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { Skeleton } from "@material-ui/lab";
import { Grid, Typography } from "@material-ui/core";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const validationAccountService = new ValidationAccountService(apiClient);

const ValidationAccountList = ({ onSelect, onAddClick }) => {
  const useCoreEntitySlice = useCoreEntityContext();

  const facilityID = useSelector((state) => useCoreEntitySlice ? state.coreEntities.ContextID : state.entities.ContextID)
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const classes = useStyles();
  const ITEM_LIMIT = 15;
  const { hasPermissions } = useHasPermissions();
  const viewValidationAccounts = hasPermissions(["validationaccounts.view"]);
  const addValidationAccounts = hasPermissions(["validationaccounts.add"]);
  const editValidationAccounts = hasPermissions(["validationaccounts.edit"]);
  const deleteValidationAccounts = hasPermissions([
    "validationaccounts.delete"
  ]);
  const { textConfirmation } = useConfirmationDialog();
  const [validationAccounts, setValidationAccounts] = useState({
    totalCount: 0,
    collection: [],
    display: []
  });
  const [isLoading, setIsLoading] = useState();
  const { validationAccountData } = useValidationAccountData();
  const [merchantAcctData, validationAccountDispatch] = validationAccountData;
  const enqueueSnackbar = useEnqueueSnackbar();

  const fillValidationAccountList = useCallback(
    async (scopeAwareFacilityID, limit, page, searchTerm) => {
      let response;
      try {
        setIsLoading(true);
        response = await validationAccountService.getValidationAccounts(
          scopeAwareFacilityID,
          limit,
          page ? (page - 1) * limit : 0,
          searchTerm
        );
      } catch (e) {
        setIsLoading(false);
        enqueueSnackbar("Failed to retrieve validation accounts", {
          variant: "error",
          tag: "FailedToRetrieveValidationAccounts"
        });
        return;
      }
      setIsLoading(false);
      setValidationAccounts(response.data);
      formatValidationAccountData(response.data.collection);
    },
    [
      scopeAwareFacilityID,
      merchantAcctData.validationAccountPage,
      merchantAcctData.validationAccountSearchTerm,
      validationAccountService.getValidationAccounts
    ]);

  useEffect(() => {
    fillValidationAccountList(
      scopeAwareFacilityID,
      ITEM_LIMIT,
      merchantAcctData.validationAccountPage,
      merchantAcctData.validationAccountSearchTerm
    );
  }, [
    scopeAwareFacilityID,
    merchantAcctData.validationAccountPage,
    merchantAcctData.validationAccountSearchTerm,
    validationAccountService.getValidationAccounts
  ]);

  const handleDeleteValidationAccount = async e => {
    const displayIndex = validationAccounts?.display?.findIndex(
      x => x.id === e
    );
    const collectionIndex = validationAccounts?.collection?.findIndex(
      x => x.contractHolderID === e
    );
    const getTextConfirmation = true;
    let confirmed = true;

    if (displayIndex < 0 || collectionIndex < 0) {
      enqueueSnackbar("Failed to delete validation account ", {
        variant: "error",
        tag: "FailedToDeleteValidationAccount"
      });
      return;
    }

    if (getTextConfirmation) {
      confirmed = await textConfirmation({
        title: "Validation Account Delete",
        message: `To confirm the deletion of this validation account, please enter this validation account's name in the input box below.`,
        textConfirmation: validationAccounts.display[displayIndex].name
      });
    }

    if (confirmed) {
      try {
        var result = await validationAccountService.deleteValidationAccountAndUsers(e);

        if (result.status === 200) {
          let changedAccts = validationAccounts;
          changedAccts.collection.splice(collectionIndex, 1);
          formatValidationAccountData(changedAccts.collection);
        }
      } catch {
        enqueueSnackbar("Failed to delete validation account", {
          variant: "error",
          tag: "FailedToDeleteValidationAccount"
        });
        return;
      }
      enqueueSnackbar("Validation Account deleted successfully", {
        variant: "success"
      });
    }
  };

  const formatValidationAccountData = rawValidationAccounts => {
    let mappingList = [];

    rawValidationAccounts.map(validationAccount => {
      mappingList.push({
        id: validationAccount.contractHolderID,
        name: validationAccount.validationAccountName,
        "Account Number": validationAccount.contractHolderID.toString(),
        "First Name": validationAccount.contactInfo?.firstName ?? " ",
        Email: validationAccount.contactInfo?.emails?.[0]?.emailAddress ?? " "
      });
    });

    setValidationAccounts(prev => ({ ...prev, display: mappingList }));
  };

  const handleAddValidationAccount = () => {
    onAddClick();
  };

  const handleValidationAccountClick = contractHolderID => {
    onSelect(contractHolderID);
  };

  const handlePageChange = (e, page) => {
    validationAccountDispatch({
      type: onFiltersChanged,
      payload: { validationAccountSearchTerm: merchantAcctData.validationAccountSearchTerm, validationAccountPage: page }
    });
  };

  const handleSearchChange = term => {
    validationAccountDispatch({
      type: onFiltersChanged,
      payload: { validationAccountSearchTerm: term, validationAccountPage: 1 }
    });
  };

  return (
    <>
      {viewValidationAccounts && (
        isLoading ? (
          <>
          <Grid container className={clsx(`validations-accounts-panel`, classes.cardHeader)}>
            <Grid item xs={8} md={8} lg={8} className={classes.headerTitle}>
                <Typography className={clsx("title")}>
                  Validation Accounts
                </Typography>
            </Grid>
          </Grid>
          <div className={clsx(["loading",classes.skeletonWrapper])}>
          <Skeleton height={"100px"}  className={classes.skeleton} animation="wave" />
          <Skeleton height={"100px"} className={classes.skeleton} animation="wave" />
          <Skeleton height={"100px"} className={classes.skeleton} animation="wave" />
        </div>
        </>
        ) : (
        <ListView
          handleAddButton={handleAddValidationAccount}
          handleEditButton={handleValidationAccountClick}
          handleDeleteButton={handleDeleteValidationAccount}
          deleteButtonToggle={deleteValidationAccounts ? true : false}
          editButtonToggle={editValidationAccounts ? true : false}
          titleBarTitleText="Validation Accounts"
          titleBarToggle={true}
          titleBarTextToggle={true}
          titleBarAddButtonToggle={addValidationAccounts ? true : false}
          addButtonText=""
          showTableHead={true}
          paginationToggle={true}
          data={validationAccounts?.display}
          editButtonText={editValidationAccounts ? "Edit" : "View"}
          rowsToShowPerPage={[15, 25, 50]}
          rowsToShowDefault={15}
          handlePageChange={handlePageChange}
          currentPage={merchantAcctData.validationAccountPage ?? 1}
          totalCount={Math.ceil(validationAccounts.totalCount / ITEM_LIMIT)}
          queryPagination={true}
          showSearch={true}
          handleSearchChange={handleSearchChange}
          searchValue={merchantAcctData.validationAccountSearchTerm}
          sortCaseInsensitive={true}
          searchDelay={500}
          autoFocusSearchBar={true}
        />
        )
      )}
    </>
  );
};

ValidationAccountList.defaultProps = {
  onSelect: () => {},
  onAddClick: () => {}
};

ValidationAccountList.propTypes = {
  onSelect: PropTypes.func,
  onAddClick: PropTypes.func
};

export default ValidationAccountList;
