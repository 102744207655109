import React, { useState } from "react";
import { Grid, Typography, Button, TextField,  Select, MenuItem } from "@material-ui/core";
import clsx from "clsx";

import SelectableChip from "../../SelectableChip";
import { useStyles } from "./ValetInventorySearch.styles";

const ValetInventorySearch = ({ onSearch, loading }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContext, setSelectedContext] = useState(0);
  
  return (
    <>
      <Grid container item xs={12} md={6}>
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "age-container"])}>
          <Grid item xs={2} md={2}>
            <Typography className={clsx(["age-title"])}>Age</Typography>
          </Grid>
          <Grid item xs={10} md={10}>
            <SelectableChip
              text="All"
              size="small"
              variant="secondary"
              selected={true}
              title="all entries, regardless of age"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} className={clsx(["search-params-container"])}>
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "search-criteria-container"])}>
          <Grid item xs={2} md={2}>
            <Typography className={clsx(["search-criteria-title"])}>Find</Typography>
          </Grid>
          <Grid item xs={10} md={10}>
            <TextField
              className={clsx([classes.notFatInput, "search-term"])}
              id="search-term"
              size="small"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => { setSearchTerm(e.target.value); }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "search-filter-container"])}>
          <Grid item xs={2} md={2} >
            <Typography className={clsx(["search-filter-title"])}>In</Typography>
          </Grid>
          <Grid item xs={10} md={10} className={clsx([])}>
            <div className={classes.filterType}>
              <Select className={clsx([classes.notFatSelect, "filter-type"])}
                variant="outlined"
                size="small"
                id="filter-type"
                data-testid="filter-select"
                value={selectedContext}
                onChange={(e) => { setSelectedContext(e.target.value); }}
              >
                <MenuItem data-value={0} className={clsx(["filter-type-option", "credentials"])} value={0}>Credentials</MenuItem>
                <MenuItem data-value={1} className={clsx(["filter-type-option", "names"])} disabled={true} value={1}>Names</MenuItem>
                <MenuItem data-value={2} className={clsx(["filter-type-option", "license-plates"])} value={2}>License Plates</MenuItem>
              </Select>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} className={clsx([classes.searchButtonRow])}>
        <Button
          disabled={true}
          color="primary"
          className={clsx(["button", "search", classes.searchButton])}
          variant="contained"
        >
          Search
        </Button>
      </Grid>
    </>
  );
};

export default ValetInventorySearch;