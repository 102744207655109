import React from "react";
import clsx from "clsx";
import moment from "moment-timezone";
import { useStyles } from "./ValetInventoryTable.styles";
import "../../ResponsiveTable/_responsive.table.css";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";

const ValetInventoryTable = ({ results = [] }) => {
  const classes = useStyles();
  const { timeZone } = useCurrentFacilityTimezone();

  return (
    <table className={clsx(classes.resultTable, "a1-responsive")}>
      <thead className={clsx(classes.thHeader)}>
        <tr>
          <th className={clsx([classes.resultHeader])}>Ticket Number</th>
          <th className={clsx([classes.resultHeader])}>Customer</th>
          <th className={clsx([classes.resultHeader])}>Guest Type</th>
          <th className={clsx([classes.resultHeader])}>Rate</th>
          <th className={clsx([classes.resultHeader])}>Entrance Time</th>
          <th className={clsx([classes.resultHeader])}>Make</th>
          <th className={clsx([classes.resultHeader])}>Model</th>
        </tr>
      </thead>
      
      <tbody>
      {results.map(({ ticketNumber, firstName, lastName, guestType, rate, entranceTime, make, model }, index) => (
            <tr key={`${ticketNumber}-${index}`}>
              <td data-column="Ticket Number" className={clsx([classes.column])}>
                {ticketNumber}
              </td>
              <td data-column="Customer" className={clsx([classes.column])}>
                {[firstName, lastName].filter(Boolean).join(" ")}
              </td>
              <td data-column="Guest Type" className={clsx([classes.column])}>
                {guestType}
              </td>
              <td data-column="Rate" className={clsx([classes.column])}>
                {rate}
              </td>
              <td data-column="Entrance Time" className={clsx([classes.column])}>
                <span>{moment.utc(entranceTime).tz(timeZone).format("MM/DD/YYYY h:mm A")}</span>
              </td>
              <td data-column="Make" className={clsx([classes.column])}>
                {make}
              </td>
              <td data-column="Model" className={clsx([classes.column])}>
                {model}
              </td>
            </tr>
          ))}
        </tbody>
    </table>
  );
};

export default ValetInventoryTable;
